<template>
  <div class="act-list-box">
    <div
      class="act-list-item"
      v-for="(item, index) in list"
      :key="index"
      @click="jumpToDetail(item.id)"
      :title="item.title"
    >
      <img :src="item.main_image" alt="">
      <div class="act-item-info">
        <h5>{{item.title}}</h5>
        <p>截止日期: {{item.end_time_text}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/act-list.css'
import ajax from "@/utils/request";
export default {
  data() {
    return {
      list: [],
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let res = await ajax({
        method: "GET",
        url: '/api/activity/lists?page=1&limit=4',
      });
      if (!res || res.code !== 1 || !res.data || res.data.constructor !== Object) {
        return false;
      }
      const { data } = res.data;
      if (!Array.isArray(data)) {
        return false;
      }
      this.list = data;
    },
    jumpToDetail(id) {
      console.log('id:', id);
      this.$router.push({
        path: `/actDetailPage/${id}`
      });
    },
  },
}
</script>

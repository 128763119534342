<template>
  <div class="demo-list-box">
    <div
      class="demo-list-item"
      v-for="(item, index) in list"
      :key="index"
    >
      <div class="demo-list-item-img" @click="$router.push(`demoDetails/${item.id}/1`)">
        <img v-if="item.cover_image" :src="item.cover_image" alt="">
        <img v-else src="https://liaotianhongbao.oss-cn-chengdu.aliyuncs.com/uploads/20231024/01be1251694ebc995f22ffa0d9c8d119.jpeg" alt="">
        <!-- <span class="demo-type">CG</span> -->
      </div>
      <div class="demo-list-item-info">
        <div class="demo-list-item-info-row demo-list-item-info-first">
          <p @click="$router.push(`demoDetails/${item.id}/1`)">{{item.name}}</p>
          <span class="demo-num demo-eye bg-default">{{item.view_count || 0}}</span>
        </div>
        <div class="demo-list-item-info-row demo-list-item-info-last">
          <div class="demo-list-item-info-user" @click="$router.push(`demoPage?user_id=${item.user_id}`)">
            <img :src="item.user.avatar || defaultAvatar" alt="">
            {{item.user.username}}
          </div>
          <div class="demo-list-item-info-num">
            <!-- class加上active表示高亮 -->
            <span v-if="item.you_star" class="demo-num demo-like bg-default active" title="点赞" @click="zan(item)">{{item.star_count || 0}}</span>
            <span v-else class="demo-num demo-like bg-default" title="点赞" @click="zan(item)">{{item.star_count || 0}}</span>
            <span v-if="item.you_collect" class="demo-num demo-focus bg-default active" title="关注" @click="handleAddCollect(item)">{{item.collect_count || 0}}</span>
            <span v-else class="demo-num demo-focus bg-default" title="关注" @click="handleAddCollect(item)">{{item.collect_count || 0}}</span>
          </div>
        </div>
      </div>
    </div>
    <p style="display: none">{{ newName }}</p>
  </div>
</template>
<script>
import '@/assets/css/demo-list.css';
import ajax from "@/utils/request";
import defaultAvatar from '@/assets/img/default_avatar.png';

import {addCollect, addStar} from "@/api";
export default {
  props: ["industryId"],
  data() {
    return {
      defaultAvatar,
      list: [],
    }
  },
  computed: {
    newName() {
      if(this.industryId) { // 判断是否有值传过来
        this.getData();
        return this.industryId
      }
      return 'John' //没有传值的默认值
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let res = await ajax({
        method: "POST",
        url: '/api/work/lists',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: {
          type: 1,
          limit: 10,
          industry_id:this.industryId
        }
      });
      if (!res || res.code !== 1 || !res.data || res.data.constructor !== Object) {
        return false;
      }
      const { data } = res.data;
      if (!Array.isArray(data)) {
        return false;
      }
      this.list = data;
    },
    async zan(item) {
      const token = this.$store.state.global.token;
      if (!token) {
        this.$message({
          message: '请登陆后查看哦~',
          type: 'warning'
        });
        this.timer = setTimeout(() => {
          this.$router.push({ path: "/loginPage" });
        }, 3000);
        return false;
      } else {
        //点赞操作
        const res = await addStar({
          work_id: item.id
        });
        if (res.code !== 1) {
          this.$message.error(res.msg);
        } else {
          this.$message.success(res.msg);
          this.getData();
        }
      }
    },
    async handleAddCollect(item) {
      const token = this.$store.state.global.token;
      if (!token) {
        this.$message({
          message: '请登陆后查看哦~',
          type: 'warning'
        });
        this.timer = setTimeout(() => {
          this.$router.push({ path: "/loginPage" });
        }, 3000);
        return false;
      } else {
        const res = await addCollect({
          work_id: item.id
        });
        if (res.code !== 1) {
          this.$message.error(res.msg);
        } else {
          this.$message.success(res.msg);
          this.getData();
        }
      }
    }
  },
}
</script>

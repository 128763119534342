import axios from "axios";
import Vue from "vue";

const ajax = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
});

ajax.interceptors.request.use((config) => {

  const token = window.localStorage.getItem('token')
  config.headers['token'] = token
  return config;
});

ajax.interceptors.response.use((response) => {
  return response.data;
}),
  (err) => {
    if (err.message != undefined) {
      Notification.error({
        message: "系统错误",
      });
    }
    return new Promise(() => {});
  };

export default ajax;

import homepage from "../views/home/home-page.vue";

const routes = [
  {
    path: "/",
    redirect: "/homePage"
  },
  {
    path: "/homePage",
    name: "homePage",
    component: homepage
  },
  {
    path: "/loginPage",
    name: "loginPage",
    component: () => import("../views/login/login-page.vue")
  },
  {
    path: "/registerPage",
    name: "registerPage",
    component: () => import("../views/register/register-page.vue")
  },
  //作品资源页面
  {
    path: "/workResourcesPage",
    name: "workResourcesPage",
    component: () => import("../views/workResources/work-resources-page.vue")
  },
  //公告列表
  {
    path: "/noticePage",
    name: "noticePage",
    component: () => import("../views/notice/notice-page.vue")
  },
  //奖励列表
  {
    path: "/awardPage",
    name: "awardPage",
    component: () => import("../views/award/award-page.vue")
  },
  //交互奖励列表
  {
    path: "/awardInteractPage",
    name: "awardInteractPage",
    component: () => import("../views/award-interact/award-interact-page.vue")
  },
  //邀请奖励
  {
    path: "/awardInvitePage",
    name: "awardInvitePage",
    component: () => import("../views/award-invite/award-invite-page.vue")
  },
  //案例列表
  {
    path: "/demoPage",
    name: "demoPage",
    component: () => import("../views/demo/demo-page.vue")
  },
  {
    path: "/demoDetails/:id/:from",
    name: "demoDetails",
    component: () => import("../views/demo/DemoDetails.vue")
  },
  //文章列表
  {
    path: "/articlePage",
    name: "articlePage",
    component: () => import("../views/article/article-page.vue")
  },
  //文章详情
  {
    path: "/articleDetailPage/:id",
    name: "articleDetailPage",
    component: () => import("../views/article/article-detail-page.vue")
  },
  //设计师列表
  {
    path: "/designerPage/:typeId?",
    name: "designerPage",
    component: () => import("../views/designer/designer-page.vue")
  },
  //设计师详情
  {
    path: "/designerDetailPage/:id/:from",
    name: "designerDetailPage",
    component: () => import("../views/designer/designer-detail-page.vue")
  },
  //任务列表
  {
    path: "/taskPage",
    name: "taskPage",
    component: () => import("../views/task/task-page.vue")
  },
  //任务详情列表
  {
    path: "/taskDetailPage/:id/:from",
    name: "taskDetailPage",
    component: () => import("../views/task/task-detail-page.vue")
  },
  //表单提交结果
  {
    path: "/submitResultPage",
    name: "submitResultPage",
    component: () => import("../views/result/submit-result-page.vue")
  },
  //活动列表
  {
    path: "/actPage",
    name: "actPage",
    component: () => import("../views/act/act-page.vue")
  },
  //活动详情
  {
    path: "/actDetailPage/:id",
    name: "actDetailPage",
    component: () => import("../views/act/act-detail-page.vue")
  },
  //悬赏列表
  {
    path: "/offerPage",
    name: "offerPage",
    component: () => import("../views/offer/offer-page.vue")
  },
  //发布任务
  {
    path: "/releaseTask",
    name: "releaseTask",
    component: () => import("../views/releaseTask/releaseTask.vue")
  },
  // 发布作品
  {
    path: "/releaseWork",
    name: "releaseWork",
    component: () => import("../views/releaseWork/releaseWork.vue")
  },
  // 个人中心
  {
    path: "/userInfo",
    name: "userInfo",
    meta:{
      navType: 'other'
    },
    component: () => import("../views/User/UserInfo.vue")
  },
  // 协议页面
  {
    path: '/protocol',
    name: 'protocol',
    component: () => import("@/views/protocol/protocol-detail-page.vue")
  },
  {
    path: '/blocked',
    name: 'blocked',
    component: () => import("@/views/blocked/blocked-page.vue")
  },
  {
    path: '/map',
    name: 'map',
    component: () => import("@/views/map/map-page.vue")
  },
  // 表格弹窗Demo
  {
    path: '/tableModalDemo',
    name: 'tableModalDemo',
    component: () => import("@/views/table-modal-demo.vue")
  },
  // 私信列表
  {
    path: '/chatList',
    name: 'chatList',
    component: () => import("@/views/chat-list/index.vue")
  },
  // 聊天窗口
  {
    path: '/chat',
    name: 'chat',
    component: () => import("@/views/chat-modal-demo.vue")
  },
  // 订单详情
  {
    path: '/orderDetail/:id',
    name: 'orderDetail',
    component: () => import("@/views/order/detail.vue")
  },
  {
    path: '/orderDetail1/:id',
    name: 'orderDetail1',
    component: () => import("@/views/order/detail1.vue")
  },
  // 编辑个人信息
  {
    path: '/edit',
    name: 'edit',
    component: () => import("@/views/User/edit.vue")
  },
  // changePassword
  // 编辑个人信息
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import("@/views/User/changePassword.vue")
  },
];
export default routes;

import ajax from "@/utils/request";
const baseURL = "/api";
//点击获取验证码
export const getVerificationCode = data =>
  ajax({
    method: "GET",
    url: baseURL + `//sms/send?mobile=${data}&event=mobilelogin`
  });

//验证码登录
export const verificationCodeLogin = data =>
  ajax({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    url: baseURL + "//user/mobilelogin",
    data
  });

//账号密码登录
export const accountPasswordLogin = data =>
  ajax({
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    url: baseURL + "//user/login",
    data
  });

// 文件上传
export const uploadFile = data =>
  ajax({
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data"
    },
    url: `${window.location.host === 'acg.ohomer.cn' ? "https://toucg.ohomer.cn" : ''}/api/common/upload`,
    data
  });

//获取轮播图数据
export const getBannerData = data =>
  ajax({
    method: "GET",
    url: baseURL + `//index/getBanner?type=${data}`
  });

// 获取页面筛选分类数据
export const getSelectData = pageKeys =>
  ajax({
    method: "GET",
    url: baseURL + `///index/selectList?select=${pageKeys}&all=0`
  });

// 发包方注册
export const registerContractor = data =>
  ajax({
    method: "POST",
    url: `api/user/register`,
    data
  });

// 添加作品
export const addWorks = data =>
  ajax({
    method: "POST",
    url: baseURL + `/work/addWork`,
    data
  });

// 作品列表
export const worksList = data =>
  ajax({
    method: "POST",
    url: baseURL + `/work/lists`,
    data
  });

// 作品详情
export const worksDetail = id =>
  ajax({
    method: "POST",
    url: baseURL + `/work/detail`,
    data: {
      id
    }
  });

// 我的作品
export const myWorkLists = data =>
  ajax({
    method: "get",
    url: `/api/user/myWorkLists?limit=${data.limit}&page=${data.page}`
  });

// 获取作品评论
export const commentList = data =>
  ajax({
    method: "POST",
    url: `/api/work/comments`,
    data
  });

// 添加作品评论
export const addComment = data =>
  ajax({
    method: "POST",
    url: `/api/work/addComment`,
    data
  });

// 作品点赞
export const addStar = data =>
  ajax({
    method: "POST",
    url: `/api/work/addStar`,
    data
  });

// 作品收藏
export const addCollect = data =>
  ajax({
    method: "POST",
    url: `/api/work/addCollect`,
    data
  });

// 任务筛选项数据
export const projectListSelect = () =>
  ajax({
    method: "GET",
    url: `/api/project/listSelect`
  });

// 任务内容保密等级
export const contentConfidential = () =>
  ajax({
    method: "GET",
    url: `/api/common/contentConfidential`
  });

// 任务文档保密等级
export const wordConfidential = () =>
  ajax({
    method: "GET",
    url: `/api/common/wordConfidential`
  });

// 发布任务 外包
export const addProject = data =>
  ajax({
    method: "POST",
    url: `/api/project/add_project`,
    data
  });

<template>
  <main>
    <Banner />
    <Tab :industryId="industryId" @industryChange="changeIndustry" />
    <!-- 案例列表 Start -->
    <Demo :industryId="industryId"/>
    <!-- 案例列表 END -->
    <!-- 列表通用标题 START -->
    <div class="list-title">
      <span class="list-title-left bg-default">任务</span>
      <a href="javascript:void(0);" class="list-title-right" @click="jumpPage('/taskPage')">更多+</a>
    </div>
    <!-- 列表通用标题 END -->
    <!-- 任务列表 START -->
    <Task />
    <!-- 任务列表 END -->
    <!-- 列表通用标题 START -->
    <div class="list-title">
      <span class="list-title-left bg-default">大神推荐</span>
      <a href="javascript:void(0);" class="list-title-right" @click="jumpPage('/designerPage')">更多+</a>
    </div>
    <!-- 列表通用标题 END -->
    <!-- 大神推荐列表 START -->
    <Designer />
    <!-- 大神推荐列表 END -->
    <!-- 列表通用标题 START -->
    <div class="list-title">
      <span class="list-title-left bg-default">活动</span>
      <a href="javascript:void(0);" class="list-title-right" @click="jumpPage('/actPage')">更多+</a>
    </div>
    <!-- 列表通用标题 END -->
    <!-- 活动列表 START -->
    <Act />
    <!-- 活动列表 END -->
  </main>
</template>
<script>
import "@/assets/css/common.css";
import '@/assets/css/main.css';
import '@/assets/css/main-tab.css';
import '@/assets/css/list-title.css';
import Banner from "./Banner.vue";
import Demo from "./Demo.vue";
import Task from "./Task.vue";
import Designer from "./Designer.vue";
import Act from "./Act.vue";
import Tab from './Tab.vue';
export default {
  components: {
    Banner,
    Demo,
    Task,
    Designer,
    Act,
    Tab,
  },
  data() {
    return {
      activeIndex: "1",
      activeIndex2: "1",
      serachInput: "",
      // 这两个后续在数据里给出不同的，并且点击可以切换
      Like: true,
      collection: false,
      sawiperBoxContentArr: [
        {
          url: require("../../assets/img/icon-tab-demo.png"),
          text: "游戏",
        },
        {
          url: require("../../assets/img/icon-tab-demo.png"),
          text: "游戏",
        },
        {
          url: require("../../assets/img/icon-tab-demo.png"),
          text: "游戏",
        },
        {
          url: require("../../assets/img/icon-tab-demo.png"),
          text: "游戏",
        },
        {
          url: require("../../assets/img/icon-tab-demo.png"),
          text: "游戏",
        },
        {
          url: require("../../assets/img/icon-tab-demo.png"),
          text: "游戏",
        },
        {
          url: require("../../assets/img/icon-tab-demo.png"),
          text: "游戏",
        },
        {
          url: require("../../assets/img/icon-tab-demo.png"),
          text: "游戏",
        },
        {
          url: require("../../assets/img/icon-tab-demo.png"),
          text: "游戏",
        },
        {
          url: require("../../assets/img/icon-tab-demo.png"),
          text: "游戏",
        },
        {
          url: require("../../assets/img/icon-tab-demo.png"),
          text: "游戏",
        },
        {
          url: require("../../assets/img/icon-tab-demo.png"),
          text: "游戏",
        },
      ],
      industryId:'',
      bannerOption: {
        initialSlide: 0,
        slideToClickedSlide: true,
        slidesPerView: 'auto',
        slidesOffsetBefore: 0,
        // loop: true,
        // autoplay: {
        //   delay: 1500,
        // },
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: '.tab-btn-next',
          prevEl: '.tab-btn-prev',
        },
      },
    };
  },
  mounted() {
    this.getBannerImgData();
  },
  methods: {
    async getBannerImgData() {
      // let res = await ajax({
      //   method: "GET",
      //   url: '/api/index/getBanner?type=1',
      // });
      // if (!res || res.code !== 1 || !Array.isArray(res.data)) {
      //   return false;
      // }
      // this.bannerImgArr = res.data;
    },
    jumpPage(path) {
      this.$router.push({ path });
    },
    changeIndustry(id) {
      this.industryId = id
    },
  },
};
</script>
<style lang="less">
.el-carousel--horizontal {
  overflow-x: visible !important;
}
.el-carousel__container .el-carousel__item {
  width: 99%;
  height: 99%;
  border-radius: 16px;
  overflow: hidden;
  background-color: rgba(0, 255, 255, 0);
}
.el-carousel__indicators--horizontal {
  opacity: 0;
}
.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.buttonOrFenye {
  display: flex;
  padding: 30px;
  height: 110px;
}
.buttonBox {
  width: 400px;
  line-height: 110px;
  button:nth-child(1) {
    width: 125px;
    height: 50px;
    font-size: 20px;
    opacity: 1;
    border-radius: 16px;
    background: rgba(255, 213, 77, 1);
    background-image: url("../../assets/img/icon-new.png");
    background-repeat: no-repeat;
    background-size: 28px;
    background-position: 22px center;
    span {
      margin-left: 32px;
      font-size: 24px;
      font-weight: 500;
      color: black;
    }
  }
  button:nth-child(2) {
    width: 125px;
    height: 50px;
    font-size: 20px;
    opacity: 1;
    border-radius: 16px;
    background: rgba(21, 176, 150, 1);
    background-image: url("../../assets/img/icon-like.png");
    background-repeat: no-repeat;
    background-size: 28px;
    background-position: 22px center;
    span {
      color: white;
      margin-left: 32px;
    }
  }
  button:nth-child(3) {
    width: 125px;
    height: 50px;
    font-size: 20px;
    opacity: 1;
    border-radius: 16px;
    background: rgba(99, 88, 245, 1);
    background-image: url("../../assets/img/icon-menu.png");
    background-repeat: no-repeat;
    background-size: 28px;
    background-position: 22px center;
    span {
      color: white;
      margin-left: 32px;
    }
  }
}
.sawiperBox {
  display: flex;
  flex: 1;
  width: 1400px;
  margin-left: 20px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .sawiperBox-left {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(56, 56, 56, 1);
    .left-icon {
      font-size: 22px;
      color: white;
      margin-left: 8px;
      line-height: 40px;
    }
  }
  .sawiperBox-content {
    display: flex;
    width: 1400px;
    overflow: hidden;
    .sawiperBox-content-box {
      display: flex;
      align-items: center;
      margin-left: 20px;
    }
    .sawiperBox-content-text {
      width: 50px;
      font-size: 24px;
      color: white;
      margin-left: 10px;
    }
  }
  .sawiperBox-right {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgba(56, 56, 56, 1);
    .right-icon {
      font-size: 22px;
      color: white;
      margin-left: 8px;
      line-height: 40px;
    }
  }
}
.work-resources-content {
  margin: 0px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 674px;
  overflow: hidden;
  .work-resources-content-child {
    display: flex;
    flex-direction: column;
    width: 330px;
    height: 325px;
    margin-right: 32px;
    margin-bottom: 24px;
    .imgBox {
      width: 330px;
      height: 245px;
      border-radius: 16px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .mainfontBox {
      width: 314px;
      height: 64px;
      margin: auto;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .consult {
        img {
          width: 18px;
          height: 18px;
          vertical-align: -3px;
        }
      }
      span {
        margin-left: 5px;
        color: rgba(184, 197, 255, 1);
      }
      .mainBox {
        flex: 1;
        display: flex;
        justify-content: space-between;
        p {
          color: white;
          font-size: 15px;
        }
      }
      .bottomBox {
        display: flex;
        justify-content: space-between;
        div {
          display: flex;
          justify-content: space-between;
        }
        .bottomBox-avatr {
          img {
            border-radius: 25px;
            border: 1px solid white;
            width: 25px;
            width: 25px;
          }
          span {
            font-size: 15px;
            color: white;
          }
        }
        .bottomBox-dianzan-box {
          width: 100px;
          .bottomBox-dianzan {
            font-size: 20px;
            color: white;
            padding-left: 28px;
          }
        }
      }
    }
  }
}
.task-title-box {
  .task-contene-left {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    .left-img-text {
      color: white;
      font-size: 34px;
      background-image: url("../../assets/img/list-title.png");
      background-position-y: center;
      padding-left: 90px;
      padding-right: 20px;
      background-repeat: no-repeat;
      height: 50px;
    }
    .line-style {
      color: #808080;
      font-size: 30px;
      border-bottom: 1px solid #808080;
      display: flex;
      flex: 1 auto;
      justify-content: flex-end;
      align-items: flex-end;
      padding-bottom: 18px;
    }
  }
  .task-contene-right {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 50px;
    overflow: hidden;
  }
}

.task-content-box {
  padding: 0 20px 20px 40px;
  width: calc(100vw - 100px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // justify-content: start;
  .task-content-item {
    width: 590px;
    height: 354px;
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: rgba(184, 197, 255, 0.05);
    border-radius: 20px;
    margin-bottom: 20px;
    img {
      width: 40%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
    .task-content-right {
      width: 329px;
      height: 354px;

      .task-content-right-top {
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .task-content-right-top-left {
          display: flex;
          align-items: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 50%;
          color: white;
          margin-left: 10px;
          font-size: 15px;
          img {
            width: 35px;
            height: 35px;
            border: 1px solid #fff;
            border-radius: 50px;
            margin-right: 10px;
          }
        }
        .task-content-right-top-right {
          display: flex;
          align-items: center;
          height: 45px;
          padding: 0 20px;
          color: white;
          font-size: 15px;
          background-color: rgba(99, 88, 245, 1);
          margin-right: 20px;
        }
      }
      .task-content-right-middle {
        height: 224px;
        padding-left: 10px;
        .task-content-right-middle-title {
          font-size: 20px;
          padding: 30px 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: white;
        }
        .task-content-right-middle-tags {
          display: flex;
          flex-wrap: wrap;
          gap: 18px;
          padding: 18px 0;
          height: 80px;
          align-content: flex-start;
          span {
            padding: 0 12px;
            color: white;
            height: 25px;
            line-height: 25px;
            border-radius: 5px;
            font-size: 12px;
            background-color: rgba(21, 176, 150, 1);
          }
        }
        .task-content-right-middle-price {
          font-size: 20px;
          font-weight: bold;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: rgba(184, 197, 255, 1);
        }
      }
      .task-content-right-bottom {
        height: 25px;
        display: flex;
        justify-content: flex-end;
        font-size: 15px;
        padding-right: 37.5px;
        position: absolute;
        bottom: 37.5px;
        right: 0;
        font-size: 15px;
        color: white;
      }
    }
    .task-content-sign-up {
      color: #000;
      font-size: 20px;
      font-weight: bold;
      position: absolute;
      bottom: 10px;
      left: 30%;
      background-color: rgba(255, 213, 77, 1);
      padding: 5px 20px;
      border-radius: 2rem;
    }
    .task-content-bottom-icon {
      display: inline-block;
      width: 25px;
      height: 25px;
      background-image: url(../../assets/img/icon-right-bottom.png);
      background-size: 100% 100%;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

// 大神推荐区
.recommend-content {
  padding: 0 20px 20px 40px;
  width: calc(100% - 100px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .recommend-content-item {
    width: 835px;
    height: 345px;
    border: 1px solid #808080;
    padding: 25px;
    border-radius: 25px;
    margin-bottom: 25px;
    .recommend-content-item-top {
      display: flex;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50px;
        background-color: red;
      }
      .recommend-content-item-top-info {
        padding-left: 37.5px;
        flex: 1 auto;
        .recommend-content-item-top-info-first {
          width: 100%;
          height: 62.5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .recommend-user-text {
            font-size: 25px;
            color: white;
            .recommend-user-tag {
              font-size: 20px;
              padding: 5px 12.5px;
              background-color: rgba(255, 213, 77, 1);
              border-radius: 12.5px 0 12.5px 0;
              margin-left: 25px;
            }
          }
          .recommend-add {
            background-image: url("../../assets/img/icon-btn-add.png");
            background-repeat: no-repeat;
            display: inline-block;
            width: 45px;
            height: 37.5px;
          }
          .recommend-eval {
            background-image: url("../../assets/img/icon-btn-eval.png");
            background-repeat: no-repeat;
            display: inline-block;
            width: 45px;
            height: 37.5px;
          }
        }
        .recommend-user-info-dianyin {
          font-size: 15px;
          color: rgba(184, 197, 255, 1);
        }
      }
    }
    .recommend-content-item-middle {
      display: flex;
      justify-content: space-between;
      padding: 25px 0;
      img {
        width: 261px;
        height: 175px;
        border-radius: 12.5px;
        object-fit: cover;
      }
    }
    .recommend-content-item-bottom {
      display: flex;
      justify-content: space-between;
      span {
        color: #808080;
        font-size: 15px;
      }
    }
  }
}
// 活动内容区
.activity-content {
  padding: 25px 50px;
  width: calc(100% - 100px);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  img {
    width: 885px;
    object-fit: cover;
    border-radius: 25px;
    height: calc((50vw - 200px) * 3 / 7);
  }
  .activity-content-item-info {
    height: 50px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    h5 {
      font-size: 20px;
      color: white;
    }
    p {
      font-size: 15px;
      color: #808080;
    }
  }
}
</style>

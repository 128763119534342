<template>
  <div class="tab-box">
    <div class="tab-left">
      <el-button type="warning" class="tab-btn tab-btn-new" @click="jumpToPage(1)">最新</el-button>
      <el-button type="success" class="tab-btn tab-btn-like" @click="jumpToPage(2)">关注</el-button>
      <el-button type="primary" class="tab-btn tab-btn-all" @click="jumpToPage(1)">全部</el-button>
    </div>
    <div class="tab-right">
      <swiper
        :slides-per-view="0"
        :space-between="50"
        :options="bannerOption"
        class="tab-right-swiper"
      >
        <swiper-slide
          v-for="(item, index) in sawiperBoxContentArr"
          class="swiper-slide active"
          :key="index"
        >
          <img :src="item.image" alt="" @click="getIndustry(item)"  :id="'swiper-'+item.id"><font @click="getIndustry(item)" :id="'swiper-font-'+item.id">{{ item.name }}</font>
        </swiper-slide>
      </swiper>
      <div class="swiper-button swiper-button-prev tab-btn-prev" title="上一个"></div>
      <div class="swiper-button swiper-button-next tab-btn-next" title="下一个"></div>
    </div>
  </div>
</template>
<script>
import ajax from "@/utils/request";
export default {
  data() {
    return {
      sawiperBoxContentArr: [],
      bannerOption: {
        initialSlide: 0,
        slideToClickedSlide: true,
        slidesPerView: 'auto',
        slidesOffsetBefore: 0,
        // loop: true,
        // autoplay: {
        //   delay: 1500,
        // },
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: '.tab-btn-next',
          prevEl: '.tab-btn-prev',
        },
      },
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await ajax({
        method: "GET",
        url: '/api/index/selectList?select=industry'
      });
      if (!res || res.code !== 1 || !res.data) {
        return false;
      }
      const { industry } = res.data;
      if (Array.isArray(industry)) {
        this.sawiperBoxContentArr = industry.filter(item => item.create_time);
      }
    },
    jumpToPage(id) {
      if (id === 1) {
        this.$router.push({ path: "demoPage" });
      }
      if (id === 2) {
        //判断是否登录
        const token = this.$store.state.global.token;
        if (!token) {
          this.$message({
            message: '请登陆后查看哦~',
            type: 'warning'
          });
          this.timer = setTimeout(() => {
            this.$router.push({ path: "/loginPage" });
          }, 3000);
          return false;
        }
        this.$router.push({ path: "demoPage?attention=1" });
      }
    },
    getIndustry(item) {
      this.$emit('industryChange', item.id)
      var idPrefix = 'swiper-';

      // 获取所有具有这个前缀的元素
      var elements = document.querySelectorAll('[id^="' + idPrefix + '"]');

      // 遍历所有元素并删除背景颜色样式
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.borderRadius = null;
        elements[i].style.border = null;
        elements[i].style.color = null;
      }
      var element = document.getElementById('swiper-'+item.id);
      var elementFont = document.getElementById('swiper-font-'+item.id);
      // 修改样式
      element.style.borderRadius = '10px';
      element.style.border = '1px solid #eaed46';
      elementFont.style.color = '#eaed46'
    }
  }
}
</script>

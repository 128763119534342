<template>
  <footer>
    <div class="footer-left">
      <div class="footer-left-top">
        <a href="javascript:void(0);" title="网站首页" @click="jumpToPage('/homePage')">网站首页</a>
<!--        <a href="javascript:void(0);" title="联系方法" @click="jumpToPage('')">联系方法</a>-->
        <a href="javascript:void(0);" title="隐私政策" @click="jumpToPage('/protocol')">隐私政策</a>
        <a href="javascript:void(0);" title="意见反馈" @click="dialogFormVisible = true">意见反馈</a>
        <a href="javascript:void(0);" title="站内地图" @click="jumpToPage('/map')">站内地图</a>
        <a href="javascript:void(0);" title="小黑屋" @click="jumpToPage('/blocked')">小黑屋</a>
      </div>
      <el-dialog title="意见反馈" :visible.sync="dialogFormVisible">
        <el-form :model="form">
          <el-form-item label="反馈内容" :label-width="formLabelWidth">
            <el-input type="textarea" :rows="5" v-model="form.contents" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="联系方式" :label-width="formLabelWidth">
            <el-input v-model="form.contact" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="feedBack()">确 定</el-button>
        </div>
      </el-dialog>
      <div class="footer-left-bottom">
        Copyright © www.AxureUX.com, All Rights Reserved.
      </div>
    </div>
    <div class="footer-right">
      <div class="footer-right-top">
        <a href="javascript:void(0);">
          400-000-0000
        </a>
      </div>
      <div class="footer-right-bottom">
        <a href="javascript:void(0);">
          Windir@AxureUX.com
        </a>
      </div>
    </div>
  </footer>
</template>
<script>
import '@/assets/css/footer.css'
import ajax from "@/utils/request";
export default {
  data() {
    return {
      dialogFormVisible: false,
      form: {
        contents: '',
        contact: '',
      },
      formLabelWidth: '120px'
    };
  },
  methods: {
    jumpToPage(path) {
      this.$router.push({ path });
    },
    async feedBack() {
      let that = this;
      if (!that.form.contents) {
        that.$message({
          message: '反馈内容不能为空哦~',
          type: 'warning'
        });
        return false;
      }
      if (!that.form.contact) {
        that.$message({
          message: '联系方式不能为空哦~',
          type: 'warning'
        });
        return false;
      }
      let res = await ajax({
        method: "POST",
        url: `/api/common/setFeedBack?contents=${that.form.contents}&contact=${that.form.contact}`,
        headers: {'content-type': 'application/x-www-form-urlencoded'},
        data: {
          contents: that.contents,
          contact: that.contact,
        }
      });
      // console.log(res)
      if (res && res.code == 1) {
        that.dialogFormVisible = false;
        that.form.contents = '';
        that.form.contact = '';
      }
    },
  }
}
</script>

<template>
  <div class="float-nav">
    <div
      class="float-nav-item"
      :class="activeIndex === index ? 'active' : ''"
      v-for="(item, index) in list"
      :key="index"
      :title="item.content"
      @click="handleClick(index, item.href, item.image)"
    >
      <img :src="item.when_selected_icon" alt="">
      <img :src="item.icon" alt="">
    </div>
    <div class="float-nav-image" v-if="showImageSrc">
      <img :src="showImageSrc" alt="">
      <i class="el-icon-circle-close" @click="closeShowImage"></i>
    </div>
  </div>
</template>
<script>
import ajax from "@/utils/request";
export default {
  data() {
    return {
      activeIndex: null,
      showImageSrc: '',
      list: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const res = await ajax({
        method: "GET",
        url: '/api/common/getSidebar',
      });
      if (!res || res.code !== 1 || !Array.isArray(res.data)) {
        return false;
      }
      this.list = res.data.map(item => {
        let { href } = item;
        if (href && !/^http/.test(href)) {
          href = `http://${href}`;
        }
        return { ...item, href };
      });
      console.log('this.list:', this.list);
    },
    handleClick(index, href, image) {
      this.activeIndex = index;
      if (href) {
        this.showImageSrc = '';
        location.href = href;
      } else if (image) {
        this.showImageSrc = image;
      }
    },
    closeShowImage() {
      this.showImageSrc = '';
    }
  }
}
</script>
<style lang="less">
.float-nav {
  display: flex;
  flex-direction: column;
  width: 2.5rem;
  height: 15rem;
  position: fixed;
  right: 1rem;
  bottom: 5rem;
  z-index: 1000;
  &-item {
    width: 100%;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
    img {
      width: 1.5rem;
      height: 1.5rem;
    }
    img:first-child {
      display: none;
    }
    img:last-child {
      display: inline-block;
    }
  }
  &-item:first-child {
    border-top-left-radius: 1.5rem;
    border-top-right-radius: 1.5rem;
  }
  &-item:nth-child(5) {
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
  }
  &-item.active {
    background-color: rgb(255, 213, 77);
    img:first-child {
      display: inline-block;
    }
    img:last-child {
      display: none;
    }
  }
  &-image {
    width: 13rem;
    height: 13rem;
    padding: 1rem;
    position: fixed;
    top: calc((100vh - 13rem) / 2);
    left: calc((100vw - 13rem) / 2);
    background-color: #FFF;
    border-radius: 1rem;
    text-align: center;
    img {
      width: 100%;
      height: 100%;
    }
    i {
      margin-top: 1rem;
    }
  }
}
@media only screen and (orientation: portrait) {
  .float-nav {
    width: 3rem;
  }
}
</style>
<template>
  <header class="header-nav" :class="activeIndex == null ? '' : 'active'">
    <div class="header-nav-left">
      CG涂鸦
      <el-button
        :icon="navShow ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
        @click="navShow = !navShow"
      ></el-button>
    </div>
    <div class="header-nav-right">
      <div class="header-nav-center" :class="navShow ? '' : 'hide'">
        <div
          class="header-nav-list"
        >
          <div
            class="header-nav-item"
            v-for="(item, index) in navList"
            :key="index"
            @mouseover="handleHover(true, index)"
            @mouseout="handleHover(false, index)"
            :class="activeIndex === index ? 'active' : ''"
          >
            <span
              @click="jumpToPage(item.path)"
              :class="item.path ? '' : 'enabled'"
              :title="item.name"
            >{{item.name}}</span>
            <div
              class="header-nav-item-childs"
              :class="activeIndex === null ? 'hide' : ''"
            >
              <span
                v-for="(cItem, cIndex) in item.childs"
                :key="cIndex"
                :class="cItem.path ? '' : 'enabled'"
                @click="jumpToPage(cItem.path)"
                :title="cItem.name"
              >{{cItem.name}}</span>
            </div>
          </div>
        </div>
        <!-- 搜索框 START -->
        <el-input
          placeholder="请输入内容"
          prefix-icon="el-icon-search"
          v-model="searchKeyword"
          class="header-nav-search-box"
        ></el-input>
        <!-- 搜索框 END -->
        <a href="javascript:void(0);" class="header-nav-btn" title="我要发包">
          <el-button
            class="header-nav-send bg-default"
            icon="el-icon-document-add"
            @click="jumpToPage('/releaseTask')"
          >
            我要发包
          </el-button>
        </a>
      </div>
      <a
        v-if="!isLogin"
        href="javascript:void(0);"
        class="header-nav-btn header-nav-register"
        @click="jumpToPage('/registerPage')"
        title="免费注册"
      >
        免费注册
      </a>
      <el-dropdown v-if="isLogin" @command="userBtnEvent" class="header-nav-user">
        <el-avatar :src="userInfo.avatar"></el-avatar>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="$router.push('/userInfo')">
            个人中心
          </el-dropdown-item>
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <a
        href="javascript:void(0);"
        class="header-nav-btn header-nav-login"
        @click="jumpToPage('/loginPage')"
        v-else
        title="登录"
      >
        登录
      </a>
    </div>
  </header>
</template>
<script>
import '@/assets/css/header-new.css';
import "@/assets/css/common.css";
export default {
  data() {
    return {
      activeIndex: null,
      headerHeight: 2,
      isLogin: false,
      userInfo: null,
      searchKeyword: "",
      showIndex: 0,
      navType: null,
      navShow: false,
      navList: [
        { name: '首页', path: '/homePage', childs: [
          { name: '网站公告', path: '' },
          { name: '平台介绍', path: '/noticePage', },
        ] },
        { name: '设计师', path: '/designerPage', childs: [
          { name: '推荐大神', path: '/designerPage/1' },
          { name: '附近大神', path: '/designerPage/2' },
        ] },
        { name: '任务', path: '/taskPage', childs: [
          { name: '任务大厅', path: '/taskPage', },
          { name: '最新任务', path: '' },
        ] },
        { name: '作品资源', path: '/demoPage', childs: [] },
        { name: '文章', path: '/articlePage', childs: [
          { name: '精品文章', path: '' },
          { name: '最新热门', path: '' },
          { name: '推荐文章', path: '' },
        ] },
        { name: '活动', path: '/actPage', childs: [] },
        { name: '涂鸦币', path: '', childs: [
          { name: '新手帮助', path: '' },
          { name: '成就奖励', path: '/awardPage', },
          { name: '发帖赚币', path: '' },
          { name: '涂鸦悬赏', path: '' },
          { name: '邀请奖励', path: 'awardInvitePage' },
          { name: '涂鸦活动', path: '' },
          { name: '互动奖励', path: '/awardInteractPage' },
        ] },
      ],
    };
  },
  mounted() {
    this.checkLogin();
    let len = 0;
    this.navList.forEach(item => {
      if (item.childs.length > len) {
        len = item.childs.length;
      }
    });
    console.log('len:', len);
    this.headerHeight = len * 2;
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      async handler(nval, oval) {
        this.checkLogin();
        this.navShow = false;
        if (!nval.name) {
          return;
        }
        const { navType } = nval.meta;

        navType === "other"
          ? (this.navType = navType)
          : (this.navType = "main");

        await this.$nextTick();
      }
    }
  },
  methods: {
    handleHover(bool, index) {
      if (bool) {
        this.activeIndex = index;
      } else {
        this.activeIndex = null;
      }
    },
    checkLogin() {
      const token = this.$store.state.global.token;
      const userInfo = this.$store.state.global.userInfo;
      if (token) {
        this.isLogin = true;
        this.userInfo = userInfo;
      } else {
        this.isLogin = false;
        this.userInfo = null;
      }
    },
    jumpToPage(path) {
      if (path === '/releaseTask') {
        let token = this.$store.state.global.token;
        if (!token) {
          this.$message({
            message: '请登陆后操作哦~',
            type: 'warning'
          });
          this.timer = setTimeout(() => {
            this.$router.push({ path: "/loginPage" });
          }, 3000);
          return false;
        }
      }
      console.log("path:", path);
      this.checkLogin();
      this.$router.push({ path });
    },
    userBtnEvent(type) {
      console.log("type:", type);
      if (type === "logout") {
        this.$store.commit("setToken", undefined);
        this.$store.commit("setUserInfo", undefined);
        this.$router.push({ path: "/loginPage" });
        localStorage.clear()
        sessionStorage.clear()
      }
    }
  }
};
</script>

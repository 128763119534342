import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueRouter from "vue-router";
import ElementUi from "element-ui";
import axios from "axios";
import "lib-flexible";
import "lib-flexible/flexible";

import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/swiper-bundle.css';

import "element-ui/lib/theme-chalk/index.css";


import store from './store';
import moment from 'moment';

import  VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

Vue.config.productionTip = false;
Vue.prototype.$axios = axios;
Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(ElementUi);
Vue.use(VueAwesomeSwiper);
Vue.use(VueQuillEditor);

Vue.prototype.$moment = moment;

router.afterEach((to, from, next)=>{
  window,scrollTo(0, 0);
});

new Vue({
  el: "#app",
  router: router,
  store,
  render: (h) => h(App),
});

export default {
  state: {
    token: null,
    userInfo: {}
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    }
  }
};
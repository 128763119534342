<template>
  <div id="app">
    <Header></Header>
    <FloatNav></FloatNav>
    <router-view style="flex: 1" :key="key"></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import FloatNav from './components/FloatNav.vue';
export default {
  name: "App",
  components: {
    Header,
    Footer,
    FloatNav,
  },
  data() {
    return {
      hasBgPages: new Set([
        '/loginPage',
      ]),
    };
  },
  computed:{
    key(){
      return this.$route.name ? this.$route.name + new Date() : this.$route + new Date();
    },
  },
  watch: {
    $route(to) {
      if (this.hasBgPages.has(to.path)) {
        document.body.setAttribute('class', 'body-bg');
      } else {
        document.body.setAttribute('class', '');
      }
    },
  }
};
</script>

<style>
#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
footer {
  margin-top: auto;
  background-color: #f4f4f4;
  padding: 20px;
  text-align: center;
}
body {
  background-color: rgba(29, 33, 41, 1);
  min-height: 100vh;
}
* {
  padding: 0;
  margin: 0;
}
/* 隐藏滚动条轨道和滑块 */
body::-webkit-scrollbar {
  width: 0.5em;
  background-color: transparent;
}

/* 隐藏滚动条滑块 */
body::-webkit-scrollbar-thumb {
  background-color: transparent;
}

/* 隐藏滚动条角落 */
body::-webkit-scrollbar-corner {
  display: none;
}
</style>

<template>
  <div class="recommend-list-box">
    <div
      class="recommend-list-item"
      v-for="(item, index) in list"
      :key="index"
      :title="item.name"
      @click="$router.push(`designerDetailPage/${item.id}/1`)"
    >
      <div class="recommend-list-item-user">
        <img :src="item.avatar || defaultAvatar" alt="">
        <div class="recommend-user-info">
          <div class="recommend-user-info-first">
            <span>{{item.name}}<span class="recommend-user-tag">{{item.star_name}}</span></span>
            <span>
              <span class="recommend-icon bg-default recommend-add"></span>
              <span class="recommend-icon bg-default recommend-eval"></span>
            </span>
          </div>
           <p class="recommend-user-info-last">
             <span v-if="item.tags.industry_names.length > 0">{{ item.tags.industry_names[0] }}-</span>
             <span v-if="item.tags.method_names.length > 0">{{ item.tags.method_names[0] }}-</span>
             <span v-if="item.tags.style_names.length > 0">{{ item.tags.style_names[0] }}</span>
           </p>
        </div>
      </div>
      <div class="recommend-list-item-imgs">
        <img v-for="(img, i) in item.work" :key="i" :src="img" alt="">
      </div>
      <div class="recommend-list-item-foots">
        <span>{{item.catch_type === 1 ? '个人' : '公司'}}用户</span>
        <span>{{item.province}}-{{item.city}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/css/recommend-list.css'
import ajax from "@/utils/request";
import defaultAvatar from '@/assets/img/default_avatar.png';
export default {
  data() {
    return {
      defaultAvatar,
      list: [],
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let res = await ajax({
        method: "POST",
        url: '/api/designer/lists',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: {
          // type_id: 1,
          limit: 10,
        }
      });
      if (!res || res.code !== 1 || !res.data || res.data.constructor !== Object) {
        return false;
      }
      const { list } = res.data;
      if (!Array.isArray(list)) {
        return false;
      }
      this.list = list;
    },
  },
}
</script>

<template>
  <div class="task-list-box">
    <div
      class="task-list-item"
      v-for="(item, index) in list"
      :key="index"
      @click="$router.push(`taskDetailPage/${item.id}/1`)"
      :title="item.name"
    >
      <img v-if="item.main_image" :src="item.main_image" alt="">
      <img v-else src="https://liaotianhongbao.oss-cn-chengdu.aliyuncs.com/uploads/20231025/c9cb1b977b801bc70c99c67fbadabacf.jpeg" alt="">
      <div class="task-list-item-right">
        <div class="task-item-right-top">
          <div class="task-item-right-top-left" @click="$router.push(`taskPage?user_id=${item.user_id}`)">
            <img :src="item.user.avatar || defaultAvatar" alt="">
            {{item.user.username}}
          </div>
          <div class="task-item-right-top-right">
            No. {{item.order_num}}
          </div>
        </div>
        <div class="task-item-right-middle">
          <div class="task-item-right-middle-title">
            {{item.name}}
          </div>
          <div class="tack-item-right-middle-tags">
            <span v-for="(tag, index1) in item.tags_text"
                  :key="index1" >{{ tag.name }}</span>
            <span v-if="item.tech_require">{{ item.tech_require }}</span>
<!--            <span>角色机甲</span>-->
<!--            <span>半写实</span>-->
          </div>
          <div class="tack-item-right-middle-price">
            ¥{{item.price}}
          </div>
        </div>
        <div class="task-item-right-bottom" v-if="item.receive_end_time">
          报名截止：{{item.receive_end_time_text}}
        </div>
      </div>
      <div class="task-list-item-float-btn">{{statusMap[item.status || 0]}}</div>
      <span class="task-item-right-bottom-icon bg-default"></span>
    </div>
  </div>
</template>
<script>
import '@/assets/css/task-list.css';
import ajax from "@/utils/request";
import defaultAvatar from '@/assets/img/default_avatar.png';
export default {
  data() {
    return {
      defaultAvatar,
      statusMap: {
        0: '报名中',
        1: '进行中',
        2: '已结束',
      },
      list: [],
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let res = await ajax({
        method: "POST",
        url: '/api/project/lists',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: {
          // type: 1,
          limit: 9,
        }
      });
      if (!res || res.code !== 1 || !res.data || res.data.constructor !== Object) {
        return false;
      }
      const { data } = res.data;
      if (!Array.isArray(data)) {
        return false;
      }
      this.list = data;
    },
  },
}
</script>

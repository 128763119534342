<template>
  <div class="banner-swiper">
    <swiper
      :slides-per-view="0"
      :space-between="50"
      :options="bannerOption"
      
    >
      <swiper-slide
        class="swiper-slide"
        v-for="(item, index) in list"
        :key="index"
      >
        <img
          :src="item.image || ''"
          alt=""
          srcset=""
          style="width: 100%; height: 100%"
          @click="jumpToPage(item.href)"
        />
      </swiper-slide>
    </swiper>
    <div class="banner-swiper-btns">
      <div class="swiper-button swiper-button-prev banner-btn-prev" title="上一个"></div>
      <div class="swiper-button swiper-button-next banner-btn-next" title="下一个"></div>
    </div>
  </div>
</template>
<script>
import ajax from "@/utils/request";
export default {
  data() {
    return {
      list: [],
      bannerOption: {
        initialSlide: 0,
        slideToClickedSlide: true,
        slidesPerView: 1,
        spaceBetween: 20,
        centeredSlides: true,
        loop: true,
        // autoplay: {
        //   delay: 1500,
        // },
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: '.banner-btn-next',
          prevEl: '.banner-btn-prev',
        },
      },
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let res = await ajax({
        method: "GET",
        url: '/api/index/getBanner?type=1',
      });
      if (!res || res.code !== 1 || !Array.isArray(res.data)) {
        return false;
      }
      this.list = res.data;
    },
    jumpToPage(path) {
      console.log('path:', path);
      window.location.href = path;
    },
  },
}
</script>